.addlist h3 {
  display: flex;
  border-left: 3px solid #5c6be8;
  margin-bottom: 30px;
}
.addlist h3 span {
  margin-left: 20px;
}
.zsBtn {
  margin-left: 20px;
}
.zsBtn button {
  height: 40px;
}
.certificate-box > div {
  margin-bottom: 5px;
}
.certificate-box > div:last-child {
  border: 0;
  margin-bottom: 0;
}
.form-box {
  padding: 10px 20px;
}
.form-box .form {
  width: 70%;
  padding: 20px;
}
.form-box .form-bg {
  background-color: #f7f8fd;
}
.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: space-around;
  width: 70%;
}
.btn-box button {
  padding: 12px 30px;
}
.tip {
  position: relative;
  z-index: 100;
  background-color: #fff;
  box-shadow: 0 0 10px #eee;
}
